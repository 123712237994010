<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card title="Sortir" collapsed :badge="$store.getters['transferouts/isFiltered'] ? 'Filtered':''">
        <template slot="body">
          <form @submit.prevent="submitFilter">
            <b-row>
              <b-col sm="12">
                <form-select-new url="v1/branch_select2" v-model="filter.branch" label="Cabang Asal"></form-select-new>
              </b-col>
              <b-col sm="6">
                <form-date :max="maxDate" v-model="filter.date_1" label="Tanggal Mulai"></form-date>
              </b-col>
              <b-col sm="6">
                <form-date :min="minDate" v-model="filter.date_2" label="Tanggal Selesai"></form-date>
              </b-col>
              <b-col sm="12">
                <form-select-new :filter-id="idBranchArray" :queryparams="{id_regional: mainRegionalId}" url="v1/branch_select2" v-model="filter.branch_sender" label="Cabang Tujuan"></form-select-new>
              </b-col>
              <b-col sm="12">
                <form-select-static :options="utils.statuses" v-model="filter.id_status" label="Status"></form-select-static>
              </b-col>
              <b-col sm="12">
                <button-action size="sm" @clicked="submitFilter" name="Filter" featherIcon="FilterIcon"></button-action>
                <button-action size="sm" @clicked="resetFilter" name="Reset" variant="warning"></button-action>
                <button-action v-if="cP(134)" size="sm" @clicked="exportExcel" variant="warning" name="Export"></button-action>
              </b-col>
            </b-row>
          </form>
        </template>
      </my-card>
    </b-col>
    <b-col sm="12">
      <my-card title="Daftar Transfer Keluar">
        <template slot="button">
          <button-add v-if="isPusat && cP(103)" :to="{name: 'transferouts-add'}" variant="primary"></button-add>
        </template>
        <template slot="body">
          <data-tables sort-key="3" desc ref="datatables" endpoint="v1/transfer_outs" :query-params="filterQuery" :fields="fields">
            <template v-slot:1="{item}">
              <b-badge v-if="item[1]===`(belum ada realisasi)`" variant="warning">{{item[1]}}</b-badge>
              <span v-else>{{item[1]}}</span>
            </template>
            <template v-slot:3="{item}">
              {{item[3] | mindate}}
            </template>
            <template v-slot:6="{item}">
              {{item[6] | formatNumber}}
            </template>
            <template v-slot:7="data">
              <div class="d-flex justify-content-end">
                <button-link size="sm" :to="{name:'transferouts-show',params:{id: data.item[7]}}" feather-icon="FolderIcon"></button-link>
                <button-link size="sm" v-if="data.item[5]==='Transfer Sebagian'&&cP(104)" :to="{name:'transferouts-part',params:{id: data.item[7]}}" feather-icon="PlusIcon"></button-link>
                <span v-if="!data.item[9]">
                  <button-link size="sm" v-if="cP(106)&&(!data.item[8]&&!data.item[9])" :to="{name:'transferouts-edit',params:{id: data.item[7]}}" :feather-icon="data.item[1]===`(belum ada realisasi)`?`PlusIcon`:`EditIcon`"></button-link>
                  <button-delete v-if="cP(107) && (!data.item[8]&&!data.item[9])" @ondelete="deleteRow" :id="data.item[7]" :name="`${data.item[1]} (${data.item[2]})`"></button-delete>
                </span>
              </div>
            </template>
          </data-tables>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import ButtonAdd from '@/my-components/ButtonAdd.vue'
import ButtonAction from '@/my-components/ButtonAction.vue'
import DataTables from '@/my-components/DataTables.vue'
import ButtonDelete from '@/my-components/ButtonDelete.vue'
import ButtonLink from '@/my-components/ButtonLink.vue'
import FormSelectNew from '@/my-components/FormSelectNew'
import FormSelectStatic from '@/my-components/FormSelectStatic'
import FormDate from '@/my-components/FormDate'
import { mapFields } from 'vuex-map-fields'
import utils from './utils'
import {BBadge} from 'bootstrap-vue'
import FileSaver from 'file-saver'

export default {
  components:{
    ButtonAdd,
    DataTables,
    ButtonDelete,
    ButtonLink,
    FormDate,
    ButtonAction,
    FormSelectStatic,
    FormSelectNew,
    BBadge
  },
  data() {
    return {
      utils:utils,
      fields: {
        0:{sortable: true, searchable: true, label:'Kode Permintaan'},
        1:{sortable: true, searchable: true, label:'Kode'},
        2:{sortable: true, searchable: true, label:'Cabang Asal'},
        3:{sortable: true, searchable: true, label:'Tanggal'},
        4:{sortable: true, searchable: true, label:'Cabang Tujuan'},
        5:{sortable: true, searchable: true, label:'Status'},
        6:{sortable: true, searchable: true, label:'Total'},
        7:{sortable: false, searchable: false, label:''},
      }
    }
  },
  computed:{
    ...mapFields('transferouts',['filter']),
    userProfile(){
      return this.$store.state.auth.profile
    },
    minDate(){
      const min = this.filter.date_1
      if(!min) return null
      else return this.$moment(min).format()
    },
    maxDate(){
      const max = this.filter.date_2
      if(!max) return null
      else return this.$moment(max).format()
    },
    filterQuery(){
      let filters = JSON.parse(JSON.stringify(this.filter))
      if(filters.branch) filters.id_branch = filters.branch.value
      if(filters.branch_sender) filters.id_branch_sender = filters.branch_sender.value
      delete filters.branch
      delete filters.branch_sender
      return filters
    },
  },
  methods:{
    firstInit(){
      // this.$refs.branchSelect.initOption(this.$store.state.transferouts.filterBranchOptions)
      // this.$refs.branch2Select.initOption(this.$store.state.transferouts.filterBranch2Options)
      if(!this.isPusat) {
        if(!this.filter.branch_sender) {
          this.filter.branch_sender = {value:this.userProfile.id_branch,label:this.userProfile.branch_name}
        }
      }
    },
    submitFilter(){
      this.$refs.datatables.refresh()
    },
    resetFilter(){
      if(this.isPusat){
        this.filter.id_branch_sender = ""
        this.filter.branch_sender = null
      }
      this.filter.id_branch = ""
      this.filter.branch = null
      this.filter.date_1 = ""
      this.filter.date_2 = ""
      this.filter.id_status = ""
      this.$refs.datatables.refresh()
    },
    async deleteRow(payload){
      try {
        await this.$store.dispatch('transferouts/destroy',payload)
        this.$refs.datatables.refresh()
        this.toastSuccess("OK","Data Berhasil Dihapus!")
      } catch (error) {
        this.handleError(error)
      }
    },
    async exportExcel(){
      const filters = this.filter
      try {
        const res = await this.$http.get(`v1/transfer_out_export`,{params:filters,responseType: 'blob'})
        FileSaver.saveAs(res.data,'TRANSFER KELUAR')
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created(){
    this.firstInit()
  }
}
</script>

<style>

</style>