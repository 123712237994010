<template>
  <validation-provider :name="label||placeholder" :rules="rules" v-slot="validationContext">
    <b-form-group :label="label||''+(isRequired&&(label.length>0) ? ' *':'')" :label-for="id">
      <b-form-select :name="id" hidden :value="value" :options="optionsFiltered" :aria-describedby="`${id}-feedback`" :state="getValidationState(validationContext)"/>
      <v-select
      append-to-body
      :clearable="clearable"
      :calculate-position="withPopper"
      ref="vselect"
      v-bind="attrs"
      v-on="$listeners"
      :value="value"
      @input="handleInput"
      label="label"
      :options="options"
      @search="onSearch"
      @search:focus="openSelect"
      :class="[{'invalid': validationContext.errors[0]},classSize]"
      :placeholder="placeholder"
      >
        <template #no-options>
          <small class="font-italic">Ketik untuk mulai mencari {{label||placeholder}}...</small>
        </template>
        <template #list-footer>
          <li v-if="isNext" class="padding-select-small">
            <b-button size="sm" variant="outline-primary" @click="nextPage" block><feather-icon icon="ArrowDownIcon"></feather-icon> Tampilkan lebih banyak</b-button>
          </li>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">🔽</span>
        </template>
        <template #spinner="{ loading }">
          <div v-if="loading" style="border-left-color: #3f6dd1" class="vs__spinner">
            <!-- The .vs__spinner class will hide the text for me. -->
          </div>
        </template>
      </v-select>
      <b-form-invalid-feedback :id="`${id}-feedback`">{{validationContext.errors[0]}}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import {BFormGroup,BFormInvalidFeedback,BFormSelect} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import axios from '@axios'
import {BButton} from 'bootstrap-vue'
import { createPopper } from '@popperjs/core';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', id)

export default {
  name:'FormSelect',
  props:{
    label:{
      type:String,
      default:''
    },
    placeholder:{
      type:String,
      default:''
    },
    value:[Object,Array],
    rules:Object,
    url:String,
    size:String,
    queryparams:Object,
    filterId:Array,
    clearable:{
      type:Boolean,
      default:false
    }
  },
  components:{
    BFormGroup,
    BFormSelect,
    ValidationProvider,
    BFormInvalidFeedback,
    vSelect,
    BButton
  },
  data(){
    return {
      placement: 'bottom',
      options: [],
      last_page: 1,
      current_page: 1
    }
  },
  computed:{
    optionsFiltered(){
      return this.options
    },
    isNext(){
      return this.current_page<this.last_page
    },
    attrs(){
      const attrs = JSON.parse(JSON.stringify(this.$attrs))
      delete attrs.rules
      delete attrs.size
      delete attrs.label
      return attrs
    },
    classSize(){
      if (!this.size) {
        return ''
      } else {
        return 'select-size-'+this.size
      }
    },
    isRequired(){
      const rule = this.rules
      if(_.isUndefined(rule)||_.isUndefined(rule.required)) return false
      else return this.rules.required
    }
  },
  methods:{
    handleInput(e){
      this.$emit('input', e)
    },
    openSelect(){
      this.options = []
      this.current_page = 1
      this.last_page = 1
      const loading = this.$refs.vselect.toggleLoading
      this.onSearch('', loading)
    },
    nextPage(){
      this.current_page++
      const loading = this.$refs.vselect.toggleLoading
      const search = this.$refs.vselect.search
      this.onSearch(search,loading)
    },
    onSearch(search, loading) {
      loading(true);
      this.search(loading, search, this);
    },
    search: _.debounce((loading, search, vm) => {
      axios.get(vm.url,{params:{name:search,...vm.queryparams}}).then((e) => {
        const options = vm.options
        let data = e.data
        if(vm.filterId && !vm.isPusat) data = _.filter(e.data, o => _.includes(vm.filterId, o.value))
        vm.options = _.uniqBy([...options,...data],'value')
        // vm.current_page = e.data.current_page
        // vm.last_page = e.data.last_page
        loading(false)
      })
    }, 350),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    withPopper(dropdownList, component, {width}) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset', options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({state}) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }]
      });
      return () => popper.destroy();
    }
  },
  created(){
    this.id = uuidv4()
  }

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .invalid .vs__dropdown-toggle {
    border-color: #ea5455;
  }
  .valid .vs__dropdown-toggle {
    border-color: #28c76f;
  }
  .padding-select-small {
    padding: 10px 15px;
  }
</style>