<template>
  <b-form-group :label="label" :label-for="generateId">
    <b-form-datepicker
    :name="id"
    :id="generateId"
    :value="formattedValue"
    @input="updateValue"
    :locale="locale"
    v-bind="dateAttrs"
    ></b-form-datepicker>
  </b-form-group>
</template>

<script>
import {BFormDatepicker,BFormGroup} from 'bootstrap-vue'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

export default {
  props:{
    value:{
      type:[String,Date]
    },
    label:{
      required: true,
      type: String
    }
  },
  components:{
    BFormDatepicker,
    BFormGroup
  },
  data(){
    return {
      locale:'id-ID',
      id:""
    }
  },
  computed:{
    generateId(){
      return _.camelCase(this.label)
    },
    dateAttrs(){
      const attrs = JSON.parse( JSON.stringify(this.$attrs) )
      delete attrs.value
      delete attrs.label
      delete attrs.id
      return attrs
    },
    formattedValue(){
      return this.$moment(this.value).format('YYYY-MM-DD')
    }
  },
  methods:{
    updateValue(e){
      // console.log(e)
      const date = this.$moment()
      const parsed = this.$moment(`${e} ${date.hours()}:${date.minutes()}:${date.seconds()}`).format()
      this.$emit('input',e ? parsed : '')
    }
  },
  created(){
    this.id = uuidv4()
  }
}
</script>

<style>

</style>