<template>
  <validation-provider :name="label" :rules="rules" v-slot="validationContext">
    <b-form-group :label="!hidelabel ? label : ''" :label-for="id">
      <b-form-input :name="id" hidden :state="getValidationState(validationContext)" :aria-describedby="`${id}-feedback`" :id="id" :value="value"></b-form-input>
      <v-select :clearable="clearable" @search:focus="$emit('open-search',value)" @option:selected="changed" :class="[{'invalid': validationContext.errors[0]},`select-size-${size}`]" :value="value" @input="handleInput" :options="options" label="label" :reduce="e => e.value">
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">🔽</span>
        </template>
      </v-select>
      <b-form-invalid-feedback :id="`${id}-feedback`">{{validationContext.errors[0]}}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";

import {BFormGroup,BFormInvalidFeedback,BFormInput} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', id)

export default {
  props:{
    label:{
      required: true,
      type: String
    },
    hidelabel:{
      type: Boolean,
      default:false
    },
    size:{
      type: String,
      default: 'md'
    },
    value:{
      required: false,
    },
    rules:{
      required: false,
      type: [Object,String]
    },
    options:{
      required: true,
      type: Array
    },
    clearable:{
      type: Boolean,
      default: false
    }
  },
  computed:{
    id(){
      return _.camelCase(this.label)
    }
  },
  components:{
    BFormGroup,
    vSelect,
    ValidationProvider,
    BFormInvalidFeedback,
    BFormInput
  },
  methods:{
    changed(val){
      this.$emit('value-changed',val)
    },
    handleInput(value){
      this.$emit('input', value)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .invalid .vs__dropdown-toggle {
    border-color: #ea5455;
  }
  .valid .vs__dropdown-toggle {
    border-color: #28c76f;
  }
</style>