<template>
  <span class="padding-right-small">
    <b-button :size="size" @click="click" :variant="`outline-${variant}`">
      <feather-icon :icon="featherIcon||''"></feather-icon> {{name}}
    </b-button>
  </span>
</template>

<script>
import {BButton} from 'bootstrap-vue'
export default {
  props:{
    featherIcon:{
      required: false,
    },
    name:{
      required: false,
      type: [String],
    },
    variant:{
      type: String,
      default: 'primary'
    },
    size:{
      required: false,
      type: String,
      default: ''
    }
  },
  name:'ButtonAction',
  components:{
    BButton
  },
  methods:{
    click(){
      this.$emit('clicked')
    }
  }
}
</script>

<style lang="scss" scoped>
  .padding-right-small {
    padding-right: 3px;
    padding-top: 2px;
  }
</style>